import LoginHandler from 'o365.vue.components.LoginHandler.vue';
import { autoAnimatePlugin } from 'https://cdn.omega365.com/libs/auto-animate/v1.0.0-beta.3/auto-animate-vue.js';
import { createApp, h } from 'vue';
import alert from 'o365.controls.alert.ts';
import { userSession } from 'o365.modules.configs.ts'

let pendingPromise;
export async function handleLogin() {

    const deprecatedFileName = 'o365.controls.LoginDialog.js';
    const replacementFileName = 'o365.modules.Login.LoginHandler.js';

    console.warn(`This file is deprecated (${deprecatedFileName}). Use ${replacementFileName}`);

    if (userSession.isDeveloper || window.location.host === 'omega.omega365.com') {
        const message = userSession.isDeveloper
            ? `Please note that this app is currently using a deprecated file (${deprecatedFileName}).\n\nPlease update all imports from '${deprecatedFileName}' to '${replacementFileName}' as soon as possible.`
            : `Please note that this app is currently using a deprecated file.\nThe app will still work as intented.\n\nPlease report this issue to get it resolved! (Check for duplicate WFs before reporting)`;

        alert(message, userSession.isDeveloper ? 'warning' : 'info', {
            autohide: true,
            delay: 10 * 1000,
            slimVersion: true
        });
    }

    if (pendingPromise) {
        return pendingPromise;
    } else {
        pendingPromise = new Promise(async (res) => {

            let loginHandlerRef;
            const app = await createApp({
                setup() {
                    return () => h(LoginHandler, {
                        ref: 'loginHandler', 'onClose': async () => {
                            await app.unmount();
                            appNode.remove();
                            pendingPromise = null;
                            res();
                        }
                    });
                },
                mounted() {
                    loginHandlerRef = this.$refs.loginHandler
                }
            });
            app.use(autoAnimatePlugin);

            const appNode = document.createElement('div');
            appNode.id = 'loginDialog'
            appNode.style.zIndex = 2000;
            appNode.style.width = '100%';
            appNode.style.height = '100%';
            appNode.style.position = 'fixed';
            appNode.style.top = '0';
            appNode.style.backgroundColor = 'rgba(200,200,200,0.6)';
            document.body.append(appNode);
            window.requestAnimationFrame(() => {
                app.mount('#loginDialog');
            });
        });

        return pendingPromise;
    }
    // return loginHandlerRef;
}

// RECOMPILE

